const isNoMinimum = {
  data() {
    return {
      key: 'no-minimum',
    };
  },
  computed: {
    isNoMinimum() {
      return (
        !!this.$route.path.toLowerCase().match(this.key.toLowerCase()) ||
        this.$route.query[this.key] === 'yes' ||
        this.$route.query[this.key] === 'true'
      );
    },
  },
  methods: {
    onRemoveNoMinimum() {
      if (!this.$refs.filters) return;
      if (this.$refs.filters.$refs.noMinimum) this.$refs.filters.$refs.noMinimum.onChange(false);
      if (this.$refs.filters.$refs.noMinimum1) this.$refs.filters.$refs.noMinimum1.onChange(false);
      this.$emit('onRemoveNoMinimum');
    },
  },
};

export { isNoMinimum as default };
